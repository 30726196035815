import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import RangeSlider from 'react-bootstrap-range-slider';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InputGroup from 'react-bootstrap/InputGroup';
import * as Icon from 'react-bootstrap-icons';
import axios from "axios";
import { useCardano } from '@cardano-foundation/cardano-connect-with-wallet';
//import { useCookies } from 'react-cookie';

  
const Dashboard = (props) => {

  const [json, setJson] = useState(null);
  const [stake, setStake] = useState(null);
  const [landSel, setLandSel] = useState(null);
  const [landArea, setLandArea] = useState(null);
  const [daysMining, setDaysMining ] = useState(1);
  const [pctLuck, setPctLuck ] = useState(0);
  const [lock, setLock] = useState(false);
  //const [cookies, setCookie] = useCookies(['auth']);
  const [netId, setNetId] = React.useState(null);
    
  const {
    isConnected, /*enabledWallet,*/ stakeAddress, connect, /*signMessage,*/ disconnect 
  } = useCardano();

  //setCookie('auth','abcd1234');

  const onConnect = () => {
    setNetId(Math.random().toString(36).slice(2, 7));
    console.log(netId);
  }

  const getMiningJson = async (props) => { 
    try {
      const userPosts = await axios.get("https://www.penguinsonchain.com/rest/mining.php?stake="+stake+"&time="+Date.now())
      setJson(userPosts.data);
    } catch (err) { console.error(err.message); } 
  };

  useEffect(()=>{
    const interval=setInterval(()=>{
      getMiningJson()
      setLock(false)
     },4000)
    return()=>clearInterval(interval)
  },[stake])
  
  const MiningComponents = {
    Penguins: function Penguins(props) {
      return <Row>
          {
            props['penguins'].penguins.map(penguin => (
              <Col sm={3}>
              <Card className="miningCard">
                {penguin['status'] !== 'mining' &&
                <>
                  <Card.Img variant="top" className="miningImg" src={"https://www.penguinsonchain.com/minted/preview/penguin-"+penguin['number']+".png"} />
                </>
                }
                {penguin['status'] === 'mining' &&
                <>
                  <Card.Img variant="top" className="miningImgMining" src={"https://www.penguinsonchain.com/minted/metaverse/penguin-"+penguin['number']+".png"} />
                </>
                }
                <Card.Body>
                  <Card.Title className="miningTitle">{penguin['name']} #{penguin['number']} <a href={"/penguin/"+penguin['number']} target="_blank" rel="noreferrer"><Icon.Link45deg size={16}/></a></Card.Title>
                  <Card.Text className="miningBody">
                    <Icon.PinMap size={18}/>&nbsp;Area:&nbsp;
                    {penguin['status']!=='mining' &&
                      <>
                        {penguin['area']!==landArea && <>{penguin['area']}</>}
                        {penguin['area']===landArea && <span className="goldText">{penguin['area']}, BONUS +10%</span>}<br/>
                      </>
                    }
                    {penguin['status']==='mining' &&
                      <>
                        {penguin['area']}<br/>
                      </>
                    }
                    <Icon.Fire size={18}/>&nbsp;Boost: {penguin['boost']}<br/>
                    <Icon.Gem size={18}/>&nbsp;$POC: {penguin['poc']}<br/>
                    <Icon.Lightning size={18}/>&nbsp;XP: {penguin['xp']}<br/>
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  { penguin['status'] === "free" &&
                    <ListGroup.Item className="miningButton">
                      <Row>
                        <Col>
                          <Button disabled={lock || (landSel ? false : true)} variant="primary" onClick={()=>{setLock(true);axios.get('https://www.penguinsonchain.com/rest/mining.php?penguin='+penguin['number']+'&stake='+stake+'&tag='+landSel+'&days='+daysMining+'&luck='+pctLuck);}}>
                          { landSel &&
                            <>
                              Stake for {daysMining} days
                              {pctLuck>0 &&
                              <>, luck: {pctLuck}%</>
                              }
                              &nbsp;<Icon.Hammer/>
                            </>
                          }
                          { !landSel &&
                            <>
                              Land not selected
                            </>
                          }
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  }
                  { penguin['status'] === "mining" &&
                    <ListGroup.Item className="miningStatus">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Time remaining: {penguin['time_remaining']}</Tooltip>}>
                      <ProgressBar className="miningProgressTip" animated now={penguin['completed']} label={`${penguin['completed']}%`} />
                      </OverlayTrigger>
                      Reward: <Icon.Gem size={15}/>&nbsp;{penguin['reward_poc']}, <Icon.Lightning size={15}/>&nbsp;{penguin['reward_xp']}
                    </ListGroup.Item>
                  }
                  { penguin['status'] === "claimable" &&
                    <ListGroup.Item className="miningButton"><Button disabled={lock} variant="success" onClick={()=>{setLock(true);axios.get('https://www.penguinsonchain.com/rest/mining.php?claim='+penguin['number']+'&stake='+stake)}}>Claim: <Icon.Gem size={18}/>&nbsp;{penguin['reward_poc']}, <Icon.Lightning size={18}/>&nbsp;{penguin['reward_xp']}</Button></ListGroup.Item>
                  }
                </ListGroup>
              </Card>
              </Col>
          ))}
          </Row>;
    },
    PublicLands: function PublicLands(props) {
      return <Row>
        {
          props.public_lands.map(land => (
            <Col>
              <Card className={landSel === land['tag'] ? "miningMapSelected" : "miningMap"} onClick={()=>{setLandSel(land['tag']);setLandArea(land['area']);}}>
                <Card.Body>
                  <Card.Title className="miningTitle">Land #{land['tag']} <a href={"/land/"+land['tag']} target="_blank" rel="noreferrer"><Icon.Link45deg size={16}/></a></Card.Title>
                    <Card.Text className="miningBody">
                      <Icon.PinMap size={18}/>&nbsp;Area: <span className={landSel === land['tag'] ? "goldText" : ""}>{land['area']}</span><br/>
                      <Icon.Gem size={18}/>&nbsp;$POC available: {land['poc']}<br/>
                      <Icon.Hammer size={18}/>&nbsp;$POC reserved: {land['poc_reserved']}<br/>
                    </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>;      
    }
  }
  
  if (isConnected&&stake===null&&stakeAddress!==null) setStake(stakeAddress);
  
  return (
    <Container>
      { isConnected ?
        <>
          <Form className="miningStakeAddress">
            <InputGroup className="mb-3">
              <InputGroup.Text className="miningAddressContainer">Your staking address is</InputGroup.Text>
              <Form.Control disabled placeholder="Staking address" aria-label="Staking address" value={stakeAddress}/>
              <InputGroup.Text id="basic-addon2" className="cursorPointer miningAddressContainer" onClick={()=>{disconnect();setStake(null);setJson(null);}}>Click here to logout</InputGroup.Text>
            </InputGroup>
          </Form>
          <Alert variant="success" className="miningHelp alignCenter miningAddressContainer">How to stake: 1) Click land below 2) Set options 3) Click "Stake for x days" below the penguin!</Alert>
          {json && json['info'] && json['info']['og_whale_club_card']==="true" &&
            <Alert variant="success" className="miningHelp alignCenter">
              <img alt="OG" src="https://www.penguinsonchain.com/core/images/poc_og_small.png" height="45"/>
              &nbsp;&nbsp;&nbsp;You own the OG Whale Club Card NFT and get a +5% bonus for all staking you start!&nbsp;&nbsp;&nbsp;
              <img alt="OG" src="https://www.penguinsonchain.com/core/images/poc_og_small.png" height="45"/>
            </Alert>
          }
        </>
        :
        <Alert className="base">
          <h2>Select wallet to sign in</h2>
          <Button variant="info" className="miningStakeAddress" onClick={()=>connect('eternl', onConnect)}>Eternl</Button>
          <Button variant="info" className="miningStakeAddress" onClick={()=>connect('nami', onConnect)}>Nami</Button>
          <Button variant="info" className="miningStakeAddress" onClick={()=>connect('flint', onConnect)}>Flint</Button>
          <Button variant="info" className="miningStakeAddress" onClick={()=>connect('typhon', onConnect)}>Typhon</Button>
          <h6>&nbsp;</h6>
        </Alert>
      }
      
      {/* Mining-view */}
      {!json && isConnected &&
        <>&nbsp;<Spinner animation="border" />&nbsp;Looking for Penguins...</>
      }
      
      {isConnected && json && json['penguins'] && json['penguins'].length===0 &&
        <>&nbsp;Oh no! No penguins found!</>
      }
      
      {json && isConnected &&
        <>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <MiningComponents.PublicLands public_lands={json['public_lands']} />
            </Col>
          </Row>
          {json['penguins'] && json['penguins'].length>0 && 
          <>
          <Row>
            <Col>
              <Card className="miningOption">
                Staking options:<br/>
                Set default options for staking. One day of staking equals to 5 points of XP.
                Set luck to 0 and you will keep every earned $POC.
              </Card>
            </Col>
            <Col>
              <Card className="miningOption">
                Duration: 1-21 days
                <RangeSlider
                  value={daysMining} onChange={e => setDaysMining(e.target.value)}
                  min={1} max={21} tooltipPlacement='top'
                  tooltipLabel={currentValue => `Duration: ${currentValue} day(s)`}
                />
                Staking days set: {daysMining}
                { (daysMining>6 && daysMining<14) &&
                  <>, <span className="goldText">BONUS +5%</span></>
                }
                { (daysMining>=14 && daysMining<21) &&
                  <>, <span className="goldText">BONUS +7.5%</span></>
                }
                { (daysMining==="21") &&
                  <>, <span className="goldText">BONUS +10%</span></>
                }
                <br/>Staking bonus is added if more than 6 days.
              </Card>
            </Col>
            <Col>
              <Card className="miningOption">
                Luck: 0-3%
                <RangeSlider
                  value={pctLuck} onChange={e => setPctLuck(e.target.value)}
                  min={0} max={3} tooltipPlacement='top'
                  tooltipLabel={currentValue => `Luck: ${currentValue}%`}
                />
                Gamble 1-3% of earned resources for chance to win extra $POC! Extra XP is guaranteed!
              </Card>
            </Col>
          </Row>
          </>
          }
          <Row className="justify-content-md-center">
            <MiningComponents.Penguins penguins={json}/>
          </Row>
        </>
      }
      {/* EOF Mining-view */}
    </Container>
  );
};

export default Dashboard;