import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import logo from './images/PenguinsOnChainWithPenguins.png';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Explore from "./pages/Explore";
import Land from "./pages/Land";
import Info from "./pages/Info";
import Dashboard from "./pages/Dashboard";
import Roadmap from "./pages/Roadmap";
import NoPage from "./pages/NoPage";

class App extends React.Component {
/*  constructor(props) {
    super(props);
    this.state = {
    weather: {},
  };
  //this.setWeather = this.setWeather.bind(this);
}*/
  
render() {

//const homeClass = location.pathname === "/" ? "active" : "";
//console.log(this.state);

 return(
    <Container>

      <Row className="justify-content-md-center">
        <Col md="auto">
          <img src={logo} alt="Logo"/>
        </Col>
      </Row>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="info" element={<Info />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="roadmap" element={<Roadmap />} />
            <Route path="*" element={<NoPage />} />
            <Route path="penguin" element={<Explore />}>
              <Route path=":penguinId" element={<Explore />}/>
            </Route>
            <Route path="land" element={<Land />}>
              <Route path=":landId" element={<Land />}/>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      
      <br/>

      <div>
        <Container fluid className="alignCenter">
          <Row className="fullWidth">
            <Col>© Copyright 2022-2024 - Penguins on Chain - All rights reserved<br/>
            <a href="https://twitter.com/PenguinsOnChain" target="_blank" rel="noreferrer">Twitter</a> |&nbsp;
            <a href="https://discord.com/invite/JtyAXWF8U2" target="_blank" rel="noreferrer">Discord</a> |&nbsp;
            <a href="https://jpg.store/collection/penguinsonchain" target="_blank" rel="noreferrer">Buy Penguins</a> |&nbsp;
            <a href="https://jpg.store/collection/penguinsonchain-ogwhaleclubcard" target="_blank" rel="noreferrer">Buy Whale Club Card</a>
            </Col>
          </Row>
        </Container>
      </div>

    </Container>);
}}
export default App;
