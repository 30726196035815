import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import CardGroup from 'react-bootstrap/CardGroup';

const Info = () => {

  return (
    <Container>

      <CardGroup style={{ width: '80%' }} className="mx-auto">
        <Card className="base mx-auto">
          <div id="table-of-contents" class="text-center">
            <h3>Table of Contents</h3>
            <ul class="text-center">
              <a href="#birth">Birth of a Project</a>&nbsp;&nbsp;
              <a href="#nfts">Penguins on Chain NFT</a>&nbsp;&nbsp;
              <a href="#metaverse">Metaverse</a><br/>
              <a href="#land">Land Parcel</a>&nbsp;&nbsp;
              <a href="#token">Tokenomics</a>&nbsp;&nbsp;
              <a href="#distribution">Token distribution</a>
            </ul>
          </div>
        </Card>
      </CardGroup>

      <br/>

      <CardGroup style={{ width: '80%' }} className="mx-auto">

        <Card className="base">

          <Card.Body>
            <Card.Title id="birth" style={{ fontSize: '26px' }}>Birth of a Project</Card.Title>
            <Card.Text style={{ fontSize: '22px' }}>
              <p>We started the project at the end of 2021 by developing a world generator for different land parcel sizes. From there, we created a proof-of-concept metaverse that included basic movement functionality, partial area rendering, land parcel data fetching from the database (with rendering), and a global chat.</p>
              <p>Our ultimate goal is to develop a relaxing, stress-free, and lightweight 2D metaverse that includes all basic metaverse features, from NFT image galleries to events. We will also add tasks for Penguins and new content in a light game format.</p>
              <p>Tokenomics will also be part of the metaverse. Our token $POC will be mainly distributed through staking. Staking Penguins requires a Land Parcel, and from time to time, public Land Parcels also become available.</p>
            </Card.Text>

              <hr/>
              <Card.Title id="nfts" style={{ fontSize: '26px' }}>Penguins on Chain NFT</Card.Title>
              <Card.Text style={{ fontSize: '22px' }}>
                <p>Our first NFT edition, Penguins on Chain! You can call this our OG collection. These NFTs have many unique perks that our future Penguins will not have. This collection was released on the Free-to-Mint principle, and the price was about 3.5 ADA!</p>
                <li>1,234 OG NFTs</li>
                <li>Profile picture</li>
                <li>Metaverse character</li>
                <li>Staking boost</li>
                <li>Entitled to Land parcel</li>
                <li>Land parcel discount.</li>
              </Card.Text>

              <hr/>
              <Card.Title id="metaverse" style={{ fontSize: '26px' }}>Metaverse</Card.Title>
              <Card.Text style={{ fontSize: '22px' }}>
                <p>We strive to make our metaverse primarily a very smooth experience from the user's point of view. We believe we can achieve this with a simplified 2D world.</p>
                <li>Chat</li>
                <li>Events</li>
                <li>Daily tasks</li>
                <li>Club Houses</li>
                <li>Leaderboards</li>
                <li>P2E Minigames.</li>
              </Card.Text>

              <hr/>
              <Card.Title id="land" style={{ fontSize: '26px' }}>Land Parcel</Card.Title>
              <Card.Text style={{ fontSize: '22px' }}>
                <p>Land Parcel has many utilities. Each of them contains a certain amount of $POC tokens, which can be obtained by staking Penguin NFTs. The Land Parcel will be another essential part when our Metaverse really kicks off. It's a pass into the metaverse, and it will be customizable.</p>
                <li>Pass into the metaverse</li>
                <li>Staking (aka. mineable!)</li>
                <li>Customizable land</li>
                <li>Parcel specific chat</li>
                <li>NFT Gallery</li>
              </Card.Text>
              
              <hr/>
              <Card.Title id="token" style={{ fontSize: '26px' }}>Tokenomics</Card.Title>
              <Card.Text style={{ fontSize: '22px' }}>
                <p>We believe that in the long run the project's own tokenomics will be a valued feature. Tokenomics is mainly distributed through staking functionality, it is also possible to get it by completing various tasks.</p>
                <li>Metaverse items</li>
                <li>Event tickets</li>
                <li>Lottery tickets</li>
                <li>Limited-press NFTs</li>
                <li>Ingame advertising</li>
                <li>Claimable $POC token.</li>
              </Card.Text>
              
              <hr/>
              <Card.Title id="distribution" style={{ fontSize: '26px' }}>Token distribution</Card.Title>
              <Card.Text style={{ fontSize: '22px' }}>
                <p>We started Penguins on Chain token staking on the last day of 2022. First, we will provide Land Parcels owned by the Penguins on Chain team for public staking use. The staking speed of the purchased Land Parcels will be significantly faster than the public ones!</p>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Segment</th>
                      <th>$POC amount</th>
                      <th>%</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Staking (aka. Mining)</td>
                      <td>327 680 000</td>
                      <td>78.0%</td>
                      <td>Ecosystem</td>
                    </tr>
                    <tr>
                      <td>Task rewards</td>
                      <td>32 880 000</td>
                      <td>7.8%</td>
                      <td>Daily, weekly, monthly + other task rewards</td>
                    </tr>
                    <tr>
                      <td>P2E rewards</td>
                      <td>25 250 000</td>
                      <td>6.0%</td>
                      <td>P2E Minigame rewards</td>
                    </tr>
                    <tr>
                      <td>Treasury</td>
                      <td>21 000 000</td>
                      <td>5.0%</td>
                      <td>Locked, reserved for later use</td>
                    </tr>
                    <tr>
                      <td>Penguins on Chain Team</td>
                      <td>12 500 000</td>
                      <td>3.0%</td>
                      <td>Reserved to team members</td>
                    </tr>
                    <tr>
                      <td>Pass holders airdrop</td>
                      <td>69 000</td>
                      <td>0.2%</td>
                      <td>Pass holders</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>420 000 000</td>
                      <td>100%</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Text>
          
          </Card.Body>
        
        </Card>

      </CardGroup>
    
    </Container>
  );
};

export default Info;