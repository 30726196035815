import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SoldOut from '../images/soldout.png';

const Home = () => {
  return (
    <>
      <Row className="justify-content-md-center">
        <Col md="auto">
            <img src={SoldOut} className="soldOutImg" alt="Sold out"/>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md="auto">
          The journey has begun: 1,234 Penguins have found loving, new homes!<br/>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md="auto">
          Follow us on social channels and keep your Penguins busy through the Dashboard pages!
        </Col>
      </Row>

      <br/>

      <Row className="justify-content-md-center">
        <Col md="auto">
          Penguinization is inevitable.
        </Col>
      </Row>

    </>
  );
};

export default Home;