import React from 'react';
import { useParams } from "react-router-dom";
import { Get } from 'react-axios'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';

   
function SwitchPenguin() {
  return (
    <form method="GET" action="/">
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="number" name="penguinId" placeholder="Enter number between 1-1234" />
      </Form.Group>
      <Button variant="primary" type="submit" className="buttonChangePenguin">
        Find the Penguin <Icon.Search/>
      </Button>
    </form>
  );
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Penguins on Chain - Change Penguin
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SwitchPenguin/>
      </Modal.Body>
    </Modal>
  );
}

function PenguinLogModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Penguins on Chain - Penguin #{props.penguin} Activity Log
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Get url="https://www.penguinsonchain.com/rest/penguin_log.php" params={{penguinId: props.penguin, time: Date.now()}}>
        {(error, response, isLoading, makeRequest, axios) => {
          if(error) {
            return (<div>Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button></div>)
          }
          else if(isLoading) {
            return (<div>Loading...</div>)
          }
          else if ((response!==null) && (response.data.length!==0)) {
            const sampleJSON=response.data.log;
            if (sampleJSON.length===0) return (<p>No activity</p>)
            return Object.keys(sampleJSON).map((key,i) => (
              <p key={i} className="pLogEntry">
                <span>{sampleJSON[key]['time']}: {sampleJSON[key]['message']}</span>
              </p>
            ))  
          }
        }}
      </Get>
      </Modal.Body>
    </Modal>
  );
}

const Explore = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [logShow, setLogShow] = React.useState(false);
  const params = useParams();
  const penguinId = params.penguinId == null ? "1" : params.penguinId;

  return (
    <div>
      <MyVerticallyCenteredModal penguin={penguinId} show={modalShow} onHide={() => setModalShow(false)}/>
      <PenguinLogModal penguin={penguinId} show={logShow} onHide={() => setLogShow(false)}/>
      <Get url="https://www.penguinsonchain.com/core/rest.php" params={{penguinId: penguinId}}>
        {(error, response, isLoading, makeRequest, axios) => {
          if(error) {
            return (<div>Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button></div>)
          }
          else if(isLoading) {
            return (<div>Loading...</div>)
          }
          else if ((response!==null) && (response.data.length!==0)) {
            const number = response.data[0]['number'];
            const eyes = response.data[1]['eyes'];
            const flippers = response.data[2]['flippers'];
            const head = response.data[3]['head'];
            const bill = response.data[4]['bill'];
            const necklace = response.data[5]['necklace'];
            const body = response.data[6]['body'];
            const back = response.data[7]['back'];
            const background = response.data[8]['background'];
            const birth_name = response.data[9]['birth_name'];
            const hemisphere = response.data[10]['hemisphere'];
            const area = response.data[11]['area'];
            const x = response.data[12]['x'];
            const y = response.data[13]['y'];
            const size = response.data[14]['size'];

            const reyes = parseFloat(response.data[15]['reyes']);
            const rflippers = parseFloat(response.data[16]['rflippers']);
            const rhead = parseFloat(response.data[17]['rhead']);
            const rbill = parseFloat(response.data[18]['rbill']);
            const rnecklace = parseFloat(response.data[19]['rnecklace']);
            const rbody = parseFloat(response.data[20]['rbody']);
            const rback = parseFloat(response.data[21]['rback']);
            const rbackground = parseFloat(response.data[22]['rbackground']);
            
            // eslint-disable-next-line
            const rarityScore = parseFloat(response.data[23]['rarityscore']);
            const ranking = parseFloat(response.data[24]['ranking']);
            const boost = parseFloat(response.data[25]['boost']);
            
            const poc = parseFloat(response.data[26]['poc']);
            const xp = parseFloat(response.data[27]['xp']);

            const mvPenguin = "https://www.penguinsonchain.com/minted/metaverse/penguin-"+penguinId+".png";
            const imgPenguin = "https://www.penguinsonchain.com/minted/preview/penguin-"+penguinId+".png";
            
            return (<>

      <Container className="alignCenter">
        <Button className="buttonChangePenguin" onClick={() => setModalShow(true)}>Penguins on Chain #{number} <Icon.Recycle/><br/>
        RANK: {ranking}, STAKING BOOST: {boost}x<br/>
        $POC earned: {poc}, XP: {xp}
        </Button>
      </Container>

    <Container className="alignCenter">
    <PhotoProvider speed={() => 800} easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}>
      <PhotoView src={mvPenguin}>
        <img src={mvPenguin} className="exploreImg" alt="" />
      </PhotoView>
      <PhotoView src={imgPenguin}>
        <img src={imgPenguin} className="exploreImg" alt="" />
      </PhotoView>
      <PhotoView src={"https://www.penguinsonchain.com/core/map.php?penguinId="+number}>
        <img src={"https://www.penguinsonchain.com/core/map.php?penguinId="+number} className="exploreImg" alt="" />
      </PhotoView>
    </PhotoProvider>
    </Container>

    <Container className="alignCenter">
      <Button className="buttonChangePenguin" onClick={() => setLogShow(true)}>See Penguin's Activity <Icon.List/><br/>
      </Button>
    </Container>
    
    <br/>

    <Alert key="info" variant="info">
      <i>Greeting! My name is {birth_name}, I'm an OG Penguin and I'm from the {hemisphere} Hemisphere!</i><br/>
      I'm entitled to a {size} land parcel in the area of {area}, coordinates: X={x} and Y={y}.<br/>
    </Alert>
        
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Head</th>
          <th>Eyes</th>
          <th>Flippers</th>
          <th>Bill</th>
          <th>Body</th>
          <th>Back</th>
          <th>Background</th>
          <th>Necklace</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Value</td>
          <td>{head}</td>
          <td>{eyes}</td>
          <td>{flippers}</td>
          <td>{bill}</td>
          <td>{body}</td>
          <td>{back}</td>
          <td>{background}</td>
          <td>{necklace}</td>
        </tr>
        <tr>
          <td>Rarity</td>
          <td>{rhead}%</td>
          <td>{reyes}%</td>
          <td>{rflippers}%</td>
          <td>{rbill}%</td>
          <td>{rbody}%</td>
          <td>{rback}%</td>
          <td>{rbackground}%</td>
          <td>{rnecklace}%</td>
        </tr>
        <tr>
          <td colSpan={9} className="alignCenter">RANK: {ranking}, STAKING BOOST: {boost}x</td>
        </tr>
      </tbody>
    </Table>

            </>)
          }
          return (
            <div>I'm sorry! I couldn't find the penguin!<br/>The error may be due to the fact that it has not been minted yet or the search value is incorrect.<br/>
            It may take about 24 hours to add the minted Penguin to our website.<br/>
            <Button className="buttonChangePenguin" onClick={() => setModalShow(true)}>Find another Penguin <Icon.Recycle/></Button>
            </div>
          )
        }}
      </Get>
    </div>
  );
};

export default Explore;