import React from 'react';
import { useParams } from "react-router-dom";
import { Get } from 'react-axios'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';

   
function SwitchPenguin() {
  return (
    <form method="GET" action="/">
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="text" name="landId" placeholder="Enter land parcel tag" />
      </Form.Group>
      <Button variant="primary" type="submit" className="buttonChangePenguin">
        Find the Land Parcel <Icon.Search/>
      </Button>
    </form>
  );
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Penguins on Chain - Change Land Parcel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SwitchPenguin/>
      </Modal.Body>
    </Modal>
  );
}

const Land = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const params = useParams();
  const landTag = params.landId == null ? "S3258" : params.landId;

  return (
    <div>
      <MyVerticallyCenteredModal penguin={landTag} show={modalShow} onHide={() => setModalShow(false)}/>
      <Get url="https://www.penguinsonchain.com/rest/land.php" params={{landTag: landTag}}>
        {(error, response, isLoading, makeRequest, axios) => {
          if(error) {
            return (<div>Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button></div>)
          }
          else if(isLoading) {
            return (<div>Loading...</div>)
          }
          else if ((response!==null) && (response.data.length!==0)) {
            const area = response.data[0]['area'];
            const x = response.data[1]['x'];
            const y = response.data[2]['y'];
            const tag = response.data[3]['tag'];
            const size = response.data[4]['size'];

            const poc_total = parseFloat(response.data[5]['poc_total']);
            const poc = parseFloat(response.data[6]['poc']);
            const poc_reserved = parseFloat(response.data[7]['poc_reserved']);

            const owner = response.data[8]['owner'];

            // eslint-disable-next-line
            const freeToMine = response.data[9]['free_to_mine'];
            
            return (<>

      <Container className="alignCenter">
        <Button className="buttonChangePenguin" onClick={() => setModalShow(true)}>Land Parcel: {tag} <Icon.Recycle/><br/>
        Area: {area}<br/>
        $POC available: {poc}
        </Button>
      </Container>

    <Container className="alignCenter">
    <PhotoProvider speed={() => 800} easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}>
      <PhotoView src={"https://www.penguinsonchain.com/core/land_parcel.php?tag="+tag}>
        <img src={"https://www.penguinsonchain.com/core/land_parcel.php?tag="+tag} className="exploreImg" alt="" />
      </PhotoView>
    </PhotoProvider>
    </Container>

    <Alert key="info" variant="info">
      { owner === 'Penguins on Chain Team' &&
        <i>This land parcel is owned by Penguins on Chain Team. It can be used in the metaverse as a place of events or quests. Area's $POC can be released for all Penguin NFT owners to mine/stake.<br/></i>
      }
      { owner !== 'Penguins on Chain Team' &&
        <i>This has not been minted yet.</i>
      }
    </Alert>
        
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Tag</th>
          <th>Area</th>
          <th>Size</th>
          <th>$POC Total</th>
          <th>$POC Available</th>
          <th>$POC Reserved</th>
          <th>Coordinate X</th>
          <th>Coordinate Y</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{tag}</td>
          <td>{area}</td>
          <td>{size}</td>
          <td>{poc_total}</td>
          <td>{poc}</td>
          <td>{poc_reserved}</td>
          <td>{x}</td>
          <td>{y}</td>
        </tr>
        <tr>
          <td colSpan={9} className="alignCenter">OWNER: {owner}</td>
        </tr>
      </tbody>
    </Table>

            </>)
          }
          return (
            <div>I'm sorry! I couldn't find the land parcel!<br/>The error may be due to the fact that it has not been minted yet or the search value is incorrect.<br/>
            It may take about 24 hours to add the minted land parcel to our website.<br/>
            <Button className="buttonChangePenguin" onClick={() => setModalShow(true)}>Find another Land Parcel <Icon.Recycle/></Button>
            </div>
          )
        }}
      </Get>
    </div>
  );
};

export default Land;