import { Outlet, NavLink } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const Layout = () => {
  return (
    <>
      <Row className="justify-content-md-center">
        <Col md="auto">
            <NavLink to="/home" activeClassName="activeRoute"><Button className="buttonMenu">Home <Icon.House/></Button></NavLink>
            <NavLink to="/info" activeClassName="activeRoute"><Button className="buttonMenu">Info <Icon.InfoCircle/></Button></NavLink>
            <NavLink to="/roadmap" activeClassName="activeRoute"><Button className="buttonMenu">Roadmap <Icon.SignpostSplit/></Button></NavLink>
            <NavLink to="/penguin/1"><Button className="buttonMenu">Penguins <Icon.Robot/></Button></NavLink>
            <NavLink to="/land/S3258"><Button className="buttonMenu">Lands <Icon.Geo/></Button></NavLink>
            <NavLink to="/dashboard" activeClassName="activeRoute"><Button className="buttonMenu">Dashboard <Icon.Key/></Button></NavLink>
        </Col>
      </Row>
      <hr/>
      <Outlet />
    </>
  )
};

export default Layout;