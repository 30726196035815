import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import MapAdacite from '../images/Roadmap.png';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import CardGroup from 'react-bootstrap/CardGroup';

const Map = () => {
  return (
    <Container>

      <CardGroup style={{ width: '80%' }} className="mx-auto">
      <Card className="base">
        <Card.Body>
        <p><img src={MapAdacite} className="roadmapImg" alt="Roadmap"/></p>

          <Card.Title style={{ fontSize: '26px' }}>Roadmap Overview</Card.Title>
          
          <Card.Text style={{ fontSize: '22px' }}>
            <p>Our roadmap outlines the key milestones we aim to achieve. The focus is on refining our project's concept and scope, developing tokenomics, introducing new NFTs, and launching land parcel sales and functionalities in our metaverse.</p>
            
            <Card.Title style={{ fontSize: '24px' }}>Q4 2023 - Q1 2024: Concept & Scope Refinement</Card.Title>
            <p>During this quarter, we will be working on fine-tuning the concept and scope of our project. This will involve internal discussions to ensure that our project is aligned with project needs and opportunities.</p>
              
            <Card.Title style={{ fontSize: '24px' }}>Q1-Q2 2024: Tokenomics & Use Cases</Card.Title>
            <p>The first two quarters of 2024 will be dedicated to developing the tokenomics and identifying potential use cases for our project. This will involve creating a robust economic model, exploring how our tokens can be used in various scenarios, and introducing $C4 as a secondary payment option.</p>
            
            <Card.Title style={{ fontSize: '24px' }}>Q2-Q3 2024: New Penguin NFTs</Card.Title>
            <p>In the second quarter of 2024, we will be launching a new line of penguin NFTs. These will be unique Penguin NFTs that can be used within our ecosystem & metaverse.</p>

            <Card.Title style={{ fontSize: '24px' }}>Q4 2024: Land Parcel Sales & Functionalities</Card.Title>
            <p>The third quarter will see the launch of land parcel sales within our metaverse. Users will be able to purchase, own, and interact with these virtual land parcels, which will have various functionalities and use cases.</p>

            <Card.Title style={{ fontSize: '24px' }}>Q4 2024 - Q1 2025: Metaverse Basic Features & Ongoing Development</Card.Title>
            <p>During the last two quarters of 2024, we will be rolling out the basic features of our metaverse. This will include social interaction capabilities, virtual environments, and more. We will also focus on ongoing development and improvements based on user feedback and technological advancements.</p>

            <p>By following this roadmap, we aim to build a robust and engaging ecosystem that offers value to our users and stakeholders.</p>

          </Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>

    </Container>
);
};

export default Map;